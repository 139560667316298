<template>
  <div class="news">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDataList"
      :form-inline="formInline"
      :form-item-arr="searchFormItemArr"
      :reset="false"
    />
    <!-- 表格 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editNews">
          添加
        </el-button>
      </div>
      <Table
        :operation-button-width="200"
        :item-data="itemData"
        :list-data="newsData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- <el-table
      size="small"
      :data="newsData"
      highlight-current-row
      v-loading="loading"
      border
      element-loading-text="拼命加载中"
      style="width: 100%;"
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column align="center" prop="title" label="标题"></el-table-column>
      <el-table-column align="center" prop="state" label="是否上架" width="80">
        <template slot-scope="scope">{{ scope.row.isTop == '1' ? '是' : '否' }}</template>
      </el-table-column>
      <el-table-column align="center" prop="isTop" label="是否置顶" width="80">
        <template slot-scope="scope">{{ scope.row.state == '1' ? '是' : '否' }}</template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="280">
        <template slot-scope="scope">
          <router-link :to="{ path: '/websiteManagement/addAndEditNews', query: scope.row }">
            <el-button size="mini" type="warning">编辑</el-button>
          </router-link>
          <el-button size="mini" type="danger" @click="deleteNews(scope.row.id)">删除</el-button>
          <el-button v-if="scope.row.isTop == '0'" size="mini" @click="operationNews(scope.row,'isTop','1')" type="success">上架</el-button>
          <el-button v-else-if="scope.row.isTop == '1'" size="mini" @click="operationNews(scope.row,'isTop','0')" type="danger">下架</el-button>
          <el-button v-if="scope.row.state == '0'" size="mini" @click="operationNews(scope.row,'state','1')" type="success">置顶</el-button>
          <el-button v-else-if="scope.row.state == '1'" size="mini" @click="operationNews(scope.row,'state','0')" type="danger">下沉</el-button>
        </template>
      </el-table-column>
    </el-table> -->
      <Pagination
        :get-data-list="getDataList"
        :total="total"
        :in-article="newsData.length"
        :params="formInline"
      />
    </div>
  </div>
</template>

<script>
import {
  getNewsByPage,
  deleteNews,
  addAndEditNews
} from '../../api/websiteManagement'
import Pagination from '../../components/Pagination2.vue'
import Table from '@/components/Table'
import FormSearch from '../../components/FormSearch'
export default {
  components: { Pagination, Table, FormSearch },
  data() {
    return {
      total: 0,
      formInline: {
        pageNum: 1,
        pageSize: 10
      },
      newsData: [],
      loading: false,
      itemData: [
        { label: '序号', prop: 'sortIndex' },
        { label: '标题', prop: 'title' },
        { label: '所需平台', prop: 'newsPlatListName' },
        {
          label: '是否置顶',
          prop: 'isTop',
          child: [
            { value: '1', label: '是' },
            { value: '0', label: '否' }
          ]
        },
        {
          label: '是否上架',
          prop: 'state',
          child: [
            { value: '1', label: '是' },
            { value: '0', label: '否' }
          ]
        }
      ],
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.editNews },
        { bType: 'danger', label: '删除', handleEvent: this.deleteNews },
        {
          val: 'isTop',
          num: '0',
          bType: 'success',
          label: '上架',
          handleEvent: this.operationNews,
          withParameters: 'isTop'
        },
        {
          val: 'isTop',
          num: '1',
          bType: 'danger',
          label: '下架',
          handleEvent: this.operationNews,
          withParameters: 'isTop'
        },
        {
          val: 'state',
          num: '0',
          bType: 'success',
          label: '置顶',
          handleEvent: this.operationNews,
          withParameters: 'state'
        },
        {
          val: 'state',
          num: '1',
          bType: 'danger',
          label: '下沉',
          handleEvent: this.operationNews,
          withParameters: 'state'
        }
      ],
      searchFormItemArr: [
        { type: 'input', label: '标题', value: 'title' },
        {
          type: 'select',
          label: '所需平台',
          value: 'newsPlat',
          pLabel: 'name',
          pValue: 'id',
          child: [
            { name: '所有', id: 1 },
            { name: '官网', id: 2 },
            { name: '商城', id: 3 }
          ]
        }
      ]
    }
  },
  methods: {
    // 获取列表
    getDataList(type) {
      if (!type) this.formInline.pageNum = 1
      getNewsByPage(this.formInline, res => {
        this.newsData = [...res.data.pageData]
        this.newsData.forEach(item => {
          item.newsPlatListName = ''
          if (item.newsPlatList.includes(1)) {
            item.newsPlatListName += ' 所有 '
            return
          }
          if (item.newsPlatList.includes(2)) {
            item.newsPlatListName += ' 官网 '
          }
          if (item.newsPlatList.includes(3)) {
            item.newsPlatListName += ' 商城 '
          }
        })
        this.total = res.data.total
      })
    },
    // 操作新闻上下架  置顶与否
    /*
    row是数据
    type是要改的类型
    num是改为的值
    **/
    operationNews(row, type) {
      this.$confirm('确定要操作此条新闻的状态吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const editForm = { ...row }
          editForm[type] = editForm[type] === '1' ? '0' : '1'
          addAndEditNews(editForm, () => {
            this.$message.success('成功！')
            this.getDataList(true)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 编辑
    editNews(row) {
      if (row.title) {
        this.$router.push({
          path: '/websiteManagement/addAndEditNews',
          query: { id: row.id }
        })
        return
      }
      this.$router.push({ path: '/websiteManagement/addAndEditNews' })
    },
    // 删除新闻
    deleteNews({ id }) {
      this.$confirm('确定要删除此条新闻吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteNews(id, () => {
            this.$message.success('删除成功！')
            this.getDataList(true)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}
.user-search .el-form-item {
  float: right;
}
</style>
