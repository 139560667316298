import axios from 'axios'
import router from '../router/index'
import { cmsURL } from '../systemConfiguration/index'
import store from '../vuex/store'
import {
  Message
} from 'element-ui'

export const isString = (o) => {
  return Object.prototype.toString.call(o).slice(8, -1) === 'String'
}
export const $http = axios.create({
  baseURL: cmsURL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
})
$http.interceptors.request.use(config => {
  // config.headers['Content-Type'] = 'Content-Security-Policy: upgrade-insecure-requests';
  if (config.method === 'post' && (config.data.constructor === String || config.data.constructor === Object)) {
    const obj = JSON.parse(JSON.stringify(config.data))
    if (!obj.operFlat)obj.operFlat = 3
    config.data = { ...obj }
  }
  if (localStorage.getItem('logintoken')) {
    config.headers['token'] = localStorage.getItem('logintoken')
  }
  store.commit('changeLoading', true)
  // config.headers['X-Access-Token'] = '13df6d333d9ffb3018e81389cc9ccaec'
  return config
}, error => {
  return Promise.reject(error)
})
$http.interceptors.response.use(response => {
  switch (response.data.code) {
    case 1000:
      Message({
        message: response.data.msg,
        type: 'error'
      })
      router.push('/login')
      break

    default:
      break
  }
  store.commit('changeLoading', false)
  return response.data
}, error => {
  store.commit('changeLoading', false)
  return Promise.reject(error)
})
// get数据请求方法
export const getRequest = (url, params, successCallback, failureCallback) => {
  $http.get(url, {
    params
  }).then(res => {
    if (res.code === 200 || !res.code) {
      successCallback(res)
      return
    }
    Message({
      message: res.message || res.msg,
      type: 'error'
    })
    if (failureCallback) failureCallback(res.message)
  }, () => {
    if (failureCallback) failureCallback()
  })
}
// POST请求数据方法
export const postRequest = (url, data, successCallback, failureCallback) => {
  $http.post(url, data).then(res => {
    if (res.code === 200) {
      successCallback(res)
      return
    }
    Message({
      message: res.message || res.msg,
      type: 'error'
    })
    if (failureCallback) failureCallback(res.message)
  }, () => {
    if (failureCallback) failureCallback()
  })
}
